<template>
  <div class="tooltip" :class="`tooltip--${position}`">
    <span v-if="label" class="text-xs">{{ label }}</span>

    <span v-if="text" class="tooltip__text">{{ text }}</span>

    <slot />
  </div>
</template>

<script>
export default {
  name: "Tooltip",

  props: {
    text: { type: String, default: "" },
    label: { type: String, default: "" },
    position: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_colors";
@import "@/styles/_globals";

.tooltip {
  display: flex;
  position: absolute;
  top: 0;
  border-radius: $border-radius;
  background-color: color(black, base);
  padding: 0.5rem 0.75rem;
  left: 0;
  visibility: hidden;
  color: color(white, base);

  &--cr {
    top: 50%;
    left: calc(100% + 0.25rem);
    transform: translateY(-50%);

    &::before {
      left: -5px;
      position: absolute;
      display: block;
      content: "";
      border-radius: $border-radius;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      border-color: transparent color(black, base) transparent transparent;
    }
  }

  &--tc {
    top: -3rem !important;
    left: 50%;
    transform: translateX(-50%);

    &::before {
      left: calc(50% - 3px);
      bottom: -5px;
      position: absolute;
      display: block;
      content: "";
      border-radius: $border-radius;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: color(black, base) transparent transparent transparent;
    }
  }
}
</style>
